    <!-- Start Content -->
    <div class="container py-5">
        <div class="row">

            <div class="col-lg-3" id="category_shop">
                <h1 class="h2 pb-4">Categorias</h1>
                <ul class="list-unstyled templatemo-accordion">
                    <!-- Bellas Artes -->
                    <li class="pb-0">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(1)">
                            Bellas Artes
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Bastidores y lienzos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Oleos, espátulas, paletas y accesorios</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Acrílicos, accesorios y barnices</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Acuarelas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pasteles y carbonillas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Esculturas, arcillas y gubias</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Herramientas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Papeles de acuarela</a>
                        </li>
                    </ul>
                    <!-- Artesanías y manualidades -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(2)">
                            Artesanías y manualidades
                        </a>
                        <ul class="templatemo-accordion">
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pegamentos</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pistolitas</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">washitapes</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cintas</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cartulinas y papeles origami</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Craft (perforadoras y demas)</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Tijeras y tijeras con forma</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Sellos</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Stenciles</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Grabado y estampado</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Láminas</a>
                            </li>
                            <li>
                                <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Maderas</a>
                            </li>
                        </ul>
                        </li>
                    <!-- Pinceles -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(3)">
                            Pinceles
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none pb-1">Pinceles</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pinceletas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Porta pinceles, apoya pinceles, etc.</a>
                        </li>
                    </ul>
                    <!-- Dibujo, ilustración, lettering y escritura -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(4)">
                            Dibujo, ilustración, lettering y escritura
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Lápices de grafito</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Lápices de colores</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Acuarelas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Block de dibujo y cuadernos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Plumas y tinta china</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Marcadores</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Microfibra</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Sharpie</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Caligrafía</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Portaminas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Lapiceras y bolígrafos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Resaltadores</a>
                        </li>
                    </ul>
                    <!-- Oficina, agendas y planificadores -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(5)">
                            Oficina, agendas y planificadores
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Carpetas, archivadores y clasificadores</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Resmas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Abrochadores y perforadoras</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Clips y aprieta papel</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Tijeras</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Adhesivos (cintas)</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pegamentos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Bandejas, lapiceras, varios</a>
                        </li>
                    </ul>
                    <!-- Escolar -->
                    <br>
                    <li class="pb-1">
                        <div class="dropdown">
                            <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(6)">
                            Escolar
                            </a>
                        </div>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none pb-1">Mochilas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cartucheras</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cuadernos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Calculadoras</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Geometría</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Didáctico</a>
                        </li>
                    </ul>
                    <!-- Accesorios, equipamiento y tecnología -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(7)">
                            Accesorios, equipamiento y tecnología
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none pb-1">Afiches</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cajas para pintura</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Caballetes</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Paletas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Pendrives</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Fundas</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Cables</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Mouse y teclado</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Auriculares</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Arte digital</a>
                        </li>
                    </ul>
                    <!-- Regalería y juguetería -->
                    <br>
                    <li class="pb-1">
                        <a class="collapsed d-flex justify-content-between h5 text-decoration-none" (click)="ordenarCat(8)">
                            Regalería y juguetería
                        </a>
                    </li>
                    <ul class="templatemo-accordion">
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none pb-1">Papeles de regalo</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Juguetes</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Didácticos</a>
                        </li>
                        <li>
                            <a href="#" class="collapsed justify-content-between h6 text-decoration-none">Libros infantiles y de colorear</a>
                        </li>
                    </ul>
                </ul>

            </div>

            <div class="col-lg-9">
                <div class="row">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6 pb-4">
                        <div class="d-flex">
                            <!-- <select [(ngModel)] = "seleccionado" (change)="ordenarOrd()"> -->
                            <select ng-Model = "seleccionado" (change)="ordenarOrd()">
                                    <option [ngValue]="item" *ngFor="let item of lista">{{item}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let articulo of articulos" class="col-md-4">
                        <div class="card mb-4 product-wap rounded-0">
                            <div class="card rounded-0">
                                <img class="card-img rounded-0 img-fluid" style="height: 250px;" src="../../../assets/img/articulos/{{articulo.codInterno}}/{{articulo.URL_principal}}">
                                <div class="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                    <ul class="list-unstyled">
                                        <li><a class="btn btn-success text-white mt-2" href="shop-single.html"><i class="far fa-eye"></i></a></li>
                                        <li><a class="btn btn-success text-white mt-2" href="shop-single.html"><i class="fas fa-cart-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body">
                                <a href="shop-single.html" class="h3 text-decoration-none">{{articulo.nombre}}</a>
                                <ul class="w-100 list-unstyled d-flex justify-content-between mb-0" style="height: 80px;">
                                    <li>{{articulo.articulo}}</li>
                                    <li class="pt-2">
                                        <span class="product-color-dot color-dot-red float-left rounded-circle ml-1"></span>
                                        <span class="product-color-dot color-dot-blue float-left rounded-circle ml-1"></span>
                                        <span class="product-color-dot color-dot-black float-left rounded-circle ml-1"></span>
                                        <span class="product-color-dot color-dot-light float-left rounded-circle ml-1"></span>
                                        <span class="product-color-dot color-dot-green float-left rounded-circle ml-1"></span>
                                    </li>
                                </ul>
                                <p class="text-center mb-0">$ {{articulo.precioVenta1}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div div="row">
                    <ul class="pagination pagination-lg justify-content-end">
                        <li class="page-item disabled">
                            <a class="page-link active rounded-0 mr-3 shadow-sm border-top-0 border-left-0" href="#" tabindex="-1">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link rounded-0 mr-3 shadow-sm border-top-0 border-left-0 text-dark" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link rounded-0 shadow-sm border-top-0 border-left-0 text-dark" href="#">3</a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <!-- End Content -->

    <!-- Start Brands -->
    <section class="bg-light py-5">
        <div class="container my-4">
            <div class="row text-center py-3">
                <div class="col-lg-6 m-auto">
                    <h1 class="h1">Our Brands</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        Lorem ipsum dolor sit amet.
                    </p>
                </div>
                <div class="col-lg-9 m-auto tempaltemo-carousel">
                    <div class="row d-flex flex-row">
                        <!--Controls-->
                        <div class="col-1 align-self-center">
                            <a class="h1" href="#multi-item-example" role="button" data-bs-slide="prev">
                                <i class="text-light fas fa-chevron-left"></i>
                            </a>
                        </div>
                        <!--End Controls-->

                        <!--Carousel Wrapper-->
                        <div class="col">
                            <div class="carousel slide carousel-multi-item pt-2 pt-md-0" id="multi-item-example" data-bs-ride="carousel">
                                <!--Slides-->
                                <div class="carousel-inner product-links-wap" role="listbox">

                                    <!--First slide-->
                                    <div class="carousel-item active">
                                        <div class="row">
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End First slide-->

                                    <!--Second slide-->
                                    <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End Second slide-->

                                    <!--Third slide-->
                                    <div class="carousel-item">
                                        <div class="row">
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo"></a>
                                            </div>
                                            <div class="col-3 p-md-5">
                                                <a href="#"><img class="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End Third slide-->

                                </div>
                                <!--End Slides-->
                            </div>
                        </div>
                        <!--End Carousel Wrapper-->

                        <!--Controls-->
                        <div class="col-1 align-self-center">
                            <a class="h1" href="#multi-item-example" role="button" data-bs-slide="next">
                                <i class="text-light fas fa-chevron-right"></i>
                            </a>
                        </div>
                        <!--End Controls-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Brands-->
