    <!-- Start Banner Hero -->
    <div class="container m-auto p-0">
        <div id="template-mo-zay-hero-carousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_stabilo.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_puntocero.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_posca.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_muresco.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_eterna.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_chimola.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_alba.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container">
                    <div class="row p-0">
                        <div class="mx-auto col-md-8 col-lg-12 order-lg-last">
                            <img class="img-fluid" src="./assets/img/banner_acrilex.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev text-decoration-none w-auto ps-0" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="prev">
            <i class="fas fa-chevron-left"></i>
        </a>
        <a class="carousel-control-next text-decoration-none w-auto pe-0" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="next">
            <i class="fas fa-chevron-right"></i>
        </a>
    </div>
  </div>
  <!-- End Banner Hero -->
  
  
    <!-- Start Categories of The Month -->
    <section class="container py-15">
  
        <div class="row">
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('1')" ><img src="./assets/img/bellas_artes.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h5 class="text-center mt-3 mb-3 text-cat" style="height: 20px;">Bellas Artes</h5>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('2')" ><img src="./assets/img/artesanias.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Artesanías y manualidades</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('3')" ><img src="./assets/img/pinceles.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Pinceles</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('4')" ><img src="./assets/img/dibujo.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Dibujo, ilustración, lettering y escritura</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('5')" ><img src="./assets/img/oficina.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Oficina, agendas y planificadores</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('6')" ><img src="./assets/img/escolar.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Escolar</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('7')" ><img src="./assets/img/accesorios.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Accesorios, equipamiento y tecnología</h2>
            </div>
            <div class="col-12 col-md-3 p-5 p-md-2 p-sm-4 mt-0 mt-sm-auto">
                <a (click)="onClick('8')" ><img src="./assets/img/regaleria.jpg" class="rounded-circle img-fluid p-3" style="cursor: pointer;"></a>
                <h2 class="h5 text-center mt-3 mb-3 text-cat" style="height: 20px;">Regalería y juguetería</h2>
            </div>
        </div>
    </section>
    <!-- End Categories of The Month -->
  
  
    <!-- Start Featured Product -->
    <section>
        <div class="container py-5">
            <div class="row text-center py-3">
                <div class="col-lg-6 m-auto">
                    <h1 class="h1">Productos destacados</h1>
                    <p>
                        Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 mb-4">
                    <div class="card h-100">
                        <a href="shop-single.html">
                            <img src="./assets/img/feature_prod_01.jpg" class="card-img-top" alt="...">
                        </a>
                        <div class="card-body">
                            <ul class="list-unstyled d-flex justify-content-between">
                                <li>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-muted fa fa-star"></i>
                                    <i class="text-muted fa fa-star"></i>
                                </li>
                                <li class="text-muted text-right">$240.00</li>
                            </ul>
                            <a href="shop-single.html" class="h2 text-decoration-none text-dark">Gym Weight</a>
                            <p class="card-text">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt in culpa qui officia deserunt.
                            </p>
                            <p class="text-muted">Reviews (24)</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-4">
                    <div class="card h-100">
                        <a href="shop-single.html">
                            <img src="./assets/img/feature_prod_02.jpg" class="card-img-top" alt="...">
                        </a>
                        <div class="card-body">
                            <ul class="list-unstyled d-flex justify-content-between">
                                <li>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-muted fa fa-star"></i>
                                    <i class="text-muted fa fa-star"></i>
                                </li>
                                <li class="text-muted text-right">$480.00</li>
                            </ul>
                            <a href="shop-single.html" class="h2 text-decoration-none text-dark">Cloud Nike Shoes</a>
                            <p class="card-text">
                                Aenean gravida dignissim finibus. Nullam ipsum diam, posuere vitae pharetra sed, commodo ullamcorper.
                            </p>
                            <p class="text-muted">Reviews (48)</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-4">
                    <div class="card h-100">
                        <a href="shop-single.html">
                            <img src="./assets/img/feature_prod_03.jpg" class="card-img-top" alt="...">
                        </a>
                        <div class="card-body">
                            <ul class="list-unstyled d-flex justify-content-between">
                                <li>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                    <i class="text-warning fa fa-star"></i>
                                </li>
                                <li class="text-muted text-right">$360.00</li>
                            </ul>
                            <a href="shop-single.html" class="h2 text-decoration-none text-dark">Summer Addides Shoes</a>
                            <p class="card-text">
                                Curabitur ac mi sit amet diam luctus porta. Phasellus pulvinar sagittis diam, et scelerisque ipsum lobortis nec.
                            </p>
                            <p class="text-muted">Reviews (74)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Featured Product -->
