import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor( private http: HttpClient ) { }
  getPosts(cat, order){
    return this.http.get("https://tiendadearte.com.ar/api/traearticulos.php?categoria="+cat+"&order="+order);
  }
}
