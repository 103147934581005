import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  @Input() mensaje;
  articulos: any;
  cat: number;
  order: String;
  seleccionado: string  = 'az';
  lista:string[]=["A - Z","Menor precio","Mayor precio"];

  constructor( private dataService: DataService) { }

  ngOnInit(): void {
    this.cat=parseInt(localStorage.getItem('categoria'));
    this.cat=0;
    this.order = "az";
    this.traeDatosDeAPI();
  }

  ordenar(cat, order): void {
    this.cat=cat;
    this.order = order;
    this.traeDatosDeAPI();
    console.log("Ordena todo");
  }
  ordenarCat(cat): void {
    this.cat=cat;
    this.traeDatosDeAPI();
}
  ordenarOrd(): void {
    this.order=this.seleccionado;
    this.traeDatosDeAPI();
}

  async traeDatosDeAPI(){
    console.log('trae datos desde API ****');

    this.dataService.getPosts(this.cat, this.order)
      .subscribe( (posts: any[]) => {
      this.articulos = posts;

//      this.articulos.forEach((item) => console.log(item));
    });

  }

}