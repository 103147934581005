import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ContactComponent } from './pages/contact/contact.component';

const routes: Routes = [
  { 
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },  
  {
    path: 'shop',
    component: ShopComponent
  },  
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot( routes, { useHash: true } )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
