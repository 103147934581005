import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  constructor(private router: Router ) { }
  ngOnInit(): void {
    localStorage.setItem('categoria', "1");
  }

  onClick( check: string ){
    console.log("Categoría: "+ check);
    localStorage.setItem('categoria', check);
    this.router.navigate(['/shop']);
  }
}
