<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

<div style="background-color: #eee;">
  <!-- Top Nav -->
  <nav class="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block" id="templatemo_nav_top">
      <div class="container text-light">
          <div class="w-100 d-flex justify-content-between">
              <div>
                  <i class="fa fa-envelope mx-2"></i>
                  <a class="navbar-sm-brand text-light text-decoration-none" href="mailto:tiendadearte.tuc@gmail.com">tiendadearte.tuc@gmail.com</a>
                  <i class="fa fa-phone mx-2"></i>
                  <a class="navbar-sm-brand text-light text-decoration-none" href="543815091652"> (+54)-381-509-1652</a>
              </div>
              <div>
                  <a class="text-light" href="https://www.facebook.com/tiendade.arte.7/" target="_blank" rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
                  <a class="text-light" href="https://www.instagram.com/tiendadearte.tuc/" target="_blank"><i class="fab fa-instagram fa-sm fa-fw me-2"></i></a>
                  <a class="text-light" href="https://api.whatsapp.com/send/?phone=%2B5493815091652&text&type=phone_number&app_absent=0" target="_blank"><i class="fab fa-whatsapp fa-sm fa-fw me-2"></i></a>
              </div>
          </div>
      </div>
  </nav>
  <!-- Close Top Nav -->

  <!-- Header -->

  <app-menu></app-menu>

  <!-- Close Header -->

  <!-- Modal -->
  <div class="modal fade bg-white" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
          <div class="w-100 pt-1 mb-5 text-right">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form action="" method="get" class="modal-content modal-body border-0 p-0">
              <div class="input-group mb-2">
                  <input type="text" class="form-control" id="inputModalSearch" name="q" placeholder="Search ...">
                  <button type="submit" class="input-group-text bg-success text-light">
                      <i class="fa fa-fw fa-search text-white"></i>
                  </button>
              </div>
          </form>
      </div>
  </div>
</div>

<div style="background-color: #eee;">
  <router-outlet></router-outlet>
</div>
  
    <!-- Start Footer -->
    <footer class="bg-dark" id="tempaltemo_footer">
      <div class="container">
          <div class="row">

              <div class="col-md-4 pt-5">
                  <h2 class="h2 text-white border-bottom pb-3 border-light logo">Nuestras sucursales</h2>
                  <ul class="list-unstyled text-light footer-link-list">
                      <li>
                          <i class="fas fa-map-marker-alt fa-fw"></i>
                          San Juan 955, San Miguel de Tucumán, Argentina
                      </li>
                      <li>
                          <i class="fa fa-phone fa-fw"></i>
                          <a class="text-decoration-none" href="tel:543815091652">(+54)-381-509-1652</a>
                      </li>
                      <li>
                          <i class="fas fa-map-marker-alt fa-fw"></i>
                          Av. Aconquija 1273, Yerba Buena, Tucumán, Argentina
                      </li>
                      <li>
                          <i class="fa fa-phone fa-fw"></i>
                          <a class="text-decoration-none" href="tel:543815106349">(+54)-381-510-6349</a>
                      </li>
                      <li>
                          <i class="fa fa-envelope fa-fw"></i>
                          <a class="text-decoration-none" href="mailto:info@company.com">tiendadearte.tuc@gmail.com</a>
                      </li>
                  </ul>
              </div>

              <div class="col-md-4 pt-5">
                  <h2 class="h2 text-light border-bottom pb-3 border-light">Productos</h2>
                  <ul class="list-unstyled text-light footer-link-list">
                      <li><a class="text-decoration-none" href="#">Luxury</a></li>
                      <li><a class="text-decoration-none" href="#">Sport Wear</a></li>
                      <li><a class="text-decoration-none" href="#">Men's Shoes</a></li>
                      <li><a class="text-decoration-none" href="#">Women's Shoes</a></li>
                      <li><a class="text-decoration-none" href="#">Popular Dress</a></li>
                      <li><a class="text-decoration-none" href="#">Gym Accessories</a></li>
                      <li><a class="text-decoration-none" href="#">Sport Shoes</a></li>
                  </ul>
              </div>

              <div class="col-md-4 pt-5">
                  <h2 class="h2 text-light border-bottom pb-3 border-light">Más Información</h2>
                  <ul class="list-unstyled text-light footer-link-list">
                      <li><a class="text-decoration-none" href="index.html">Página principal</a></li>
                      <li><a class="text-decoration-none" href="">Ubicaciones de la tienda</a></li>
                      <li><a class="text-decoration-none" href="#">Preguntas frecuentes</a></li>
                      <li><a class="text-decoration-none" href="contact.html">Contactenos</a></li>
                  </ul>
              </div>
          </div>

          <div class="row text-light mb-4">
              <div class="col-12 mb-3">
                  <div class="w-100 my-3 border-top border-light"></div>
              </div>
              <div class="col-auto me-auto">
                  <ul class="list-inline text-left footer-icons">
                      <li class="list-inline-item border border-light rounded-circle text-center">
                          <a class="text-light text-decoration-none" target="_blank" href="https://www.facebook.com/tiendade.arte.7/"><i class="fab fa-facebook-f fa-lg fa-fw"></i></a>
                      </li>
                      <li class="list-inline-item border border-light rounded-circle text-center">
                          <a class="text-light text-decoration-none" target="_blank" href="https://www.instagram.com/tiendadearte.tuc/"><i class="fab fa-instagram fa-lg fa-fw"></i></a>
                      </li>
                      <li class="list-inline-item border border-light rounded-circle text-center">
                          <a class="text-light text-decoration-none" target="_blank" href="https://api.whatsapp.com/send/?phone=%2B5493815091652&text&type=phone_number&app_absent=0"><i class="fab fa-whatsapp fa-lg fa-fw"></i></a>
                      </li>
                  </ul>
              </div>
              <div class="col-auto">
                  <label class="sr-only" for="subscribeEmail">Dirección de email</label>
                  <div class="input-group mb-2">
                      <input type="text" class="form-control bg-dark border-light" id="subscribeEmail" placeholder="Email address">
                      <div class="input-group-text btn-success text-light">Subscribe</div>
                  </div>
              </div>
          </div>
      </div>

      <div class="w-100 bg-black py-3">
          <div class="container">
              <div class="row pt-2">
                  <div class="col-12">
                      <p class="text-left text-light">
                          Copyright &copy; 2023 Tienda de arte 
                          | Designed by <a rel="sponsored" href="https://templatemo.com" target="_blank">TemplateMo</a>
                      </p>
                  </div>
              </div>
          </div>
      </div>

  </footer>
  <!-- End Footer -->

  <!-- Start Script -->
  <script src="assets/js/jquery-1.11.0.min.js"></script>
  <script src="assets/js/jquery-migrate-1.2.1.min.js"></script>
  <script src="assets/js/bootstrap.bundle.min.js"></script>
  <script src="assets/js/templatemo.js"></script>
  <script src="assets/js/custom.js"></script>
  <!-- End Script -->
